import React, { useContext } from "react";
import WiAddress from "../../context/WiAddress";

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const HowTo2 = (props) => {
  const ctx = useContext(WiAddress);

  let seprator = ctx.language != 1 ? "," : "،";

  let displayed_commune = ctx.commune;

  if (ctx.language == 1) {
    if (ctx.commune_ar != "") {
      displayed_commune = ctx.commune_ar;
    }
  }

  const list_names = [
    {
      howTo_text1: "Adresse",
      howTo_text2: "Votre Wi-Adresse est sur 4 caractères !",
      howTo_text3:
        "La Wi-Adresse est Simple à retenir, Facile à communiquer et avec une Précision inégalée !",
      howTo_text4: "OK, j'ai compris !",
      css_orientation: " text-left ",
      css_direction: "ltr",
      howTo_image: "tuto_howto_fr.png",
    },
    {
      howTo_text1: "عنوان",
      howTo_text2: "عنوانك يتألف من 4 أحرف فقط",
      howTo_text3: "سهل للحفظ، سهل للتواصل، وبدقة لا مثيل لها",
      howTo_text4: "نعم فهمت",
      css_orientation: " text-right ",
      css_direction: "rtl",
      howTo_image: "tuto_howto_ar.png",
    },
    {
      howTo_text1: "Address",
      howTo_text2: "Your address is just 4 characters long!",
      howTo_text3:
        "It's easy to remember, easy to communicate, and with an exceptional accuracy !",
      howTo_text4: "OK, I understand !",
      css_orientation: " text-left ",
      css_direction: "ltr",
      howTo_image: "tuto_howto_en.png",
    },
    {
      howTo_text1: "Tansa",
      howTo_text2: "Wi-Tansa inek.inem tegber kan 4 isekkilen !",
      howTo_text3:
        "D ayen ifessus i usmekti, fessus i uselɣu, yerna tiseddi ines d tin yelhan !",
      howTo_text4: "Yeṛveḥ, gziɣ !",
      css_orientation: " text-left ",
      css_direction: "ltr",
      howTo_image: "tuto_howto_en.png",
    },
  ];

  return (
    <>
      <div
        className="mt-2 border-t-2  "
        dir={list_names[ctx.language].css_direction}
      >
        <div className="flex justify-center">
          <img
            className="rounded-lg"
            src={
              "https://www.WiMapper.com/images/" +
              list_names[ctx.language].howTo_image
            }
          />
        </div>
        <div className="flex justify-center mt-6" onClick={props.closeDialog}>
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
            {list_names[ctx.language].howTo_text4}
          </button>
        </div>
      </div>
    </>
  );
};

export default HowTo2;
