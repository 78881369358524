import React, { useState } from "react";

import logo from "../wi.png";
import Language from "./Language";

const Header = (props) => {
  const [openMenu, setOpenMenu] = useState(false);

  const openMenuHandler = () => {
    setOpenMenu((prevState) => {
      return !prevState;
    });

    //console.log(props);

    props.setShowMenu((prevState) => {
      return !prevState;
    });
  };

  const menuIcon = openMenu ? (
    <div className="border-2 m-auto  rounded-lg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10 text-white "
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
  ) : (
    /*
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10 text-white m-auto"
        fill="none"
        viewBox="0 0 14 14"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </>
    */
    <>
      {/* <span className="bg-white w-5/12 h-1 mx-auto mt-4 rounded-xl "> </span>
      <span className="bg-white w-4/12 h-1 mx-auto mt-2 rounded-lg"></span>
      <span className="bg-white w-5/12 h-1 mx-auto mt-2 rounded-lg"></span> */}
      <div className="m-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="50px"
          viewBox="0 -960 960 960"
          width="50px"
          fill="#FFFFFF"
        >
          <path d="M140-254.62v-59.99h680v59.99H140ZM140-450v-60h680v60H140Zm0-195.39v-59.99h680v59.99H140Z" />
        </svg>
      </div>
    </>
  );

  return (
    // <nav className="flex justify-between w-full  bg-green-700  ">
    //   <ul className="flex flex-col border-white hover:bg-green-600 p-4 hover:cursor-pointer transition">
    //     <li className="border-t-2 border-inherit  w-8 py-1 mt-1"></li>
    //     <li className="border-t-2 border-inherit w-8 py-1"></li>
    //     <li className="border-t-2 border-inherit w-8 py-1"></li>
    //   </ul>

    //   <div className="flex items-center  font-medium text-white  text-center  hover:border-red-600">
    //     <img src={logo} className="h-14 pr-2" />
    //     <span className="font-sans">Mapper</span>
    //   </div>

    //   <div></div>
    // </nav>
    <div className="flex bg-green-700 justify-between h-14">
      <a
        className={
          "w-20 flex flex-col hover:cursor-pointer hover:bg-green-600 hover:shadow-md transition " +
          (openMenu ? "bg-green-600 border-b-4" : "")
        }
        onClick={openMenuHandler}
      >
        {menuIcon}
      </a>
      <div className="flex  p-1 " tabIndex="-1">
        <img className="h-12 my-auto" src={logo} />
        <span className="text-white text-3xl   m-auto   font-PermanentMarker">
          Mapper
        </span>
      </div>

      <div className=" z-30">
        <Language></Language>
      </div>
    </div>
  );
};

export default Header;
