import React, { useContext } from "react";

import WiAddress from "../../context/WiAddress";

const AboutUs = (props) => {
  const ctx = useContext(WiAddress);

  const list_names = [
    {
      aboutUs_text:
        "Parce que, de nos jours, la technologie est plus facilement accessible, WiMapper a su en tirer partie pour vous fournir un système d'adressage simple, innovant et précis, basé sur des Wi-Adresses.\nC'est quoi une Wi-Adresse ? c'est une adresse composée du nom de votre commune et d'un code à 4 caractères. Chaque lieu en #Algérie, en #Tunisie, en #France, ou au #Togo possède une Wi-Adresse correspondante. Votre adresse tient en seulement 4 caractères !! C'est Simple à retenir, Facile à communiquer et avec une Précision inégalée !\nAidez-nous à démocratiser le service en partageant avec vos amis.\nMerci !",
      css_orientation: " text-left ",
    },
    {
      aboutUs_text:
        "لأن التكنولوجيا أصبحت متاحة بشكل أسهل في الوقت الحالي، استخدمنا ذلك لدى وي مابر لتقديمكم نظام عناوين بسيط ودقيق ، متمثل بى <وي>عناوين \nما معنى نظام <وي>عنوان ؟ إنه عنوان يتكون من اسم بلديتك ورمز مكون من 4 أحرف. كل مكان في #الجزائر، في #تونس، في #فرنسا، أو في #توغو يحمل <وي>عنوان مقابل  \nعنوانك يتألف من 4 أحرف فقط!! سهل للحفظ، سهل للتواصل، وبدقة لا مثيل لها \nساعدونا في نشر الخدمة عن طريق مشاركتها مع أصدقائكم \nشكرًا",
      css_orientation: " text-right ",
    },
    {
      aboutUs_text:
        "Because, nowadays, technology is more easily accessible, WiMapper has leveraged it to provide you with a simple, innovative, and precise addressing system based on Wi-Addresses. \nWhat is a Wi-Address? It's an address composed of the name of your town and a 4-character code. Every location in #Algeria, #Tunisia, #France, or #Togo has a corresponding Wi-Address. \nYour address is just 4 characters long!! It's easy to remember, easy to communicate, and with an exceptional accuracy ! \nHelp us democratize the service by sharing it with your friends. \nThank you !",
      css_orientation: " text-left ",
    },
    {
      aboutUs_text:
        "Imi ass-a titiknulujiyin uɣalent fessusit i useqdec, WiMapper yezmer ad tent-yesseqdec akken ad awen-d-yefk anagraw n tansawin ifessusen, d imaynuten, yernu d iḥerfiyen, yebnan ɣef Wi-Tansawin.\nD acu-tt Wi-Tansa? D tansa yebnan s yisem n tɣiwant-nwen d tengalt n 4 n yisekkilen. Yal adeg deg #Lezzayer, #Tunes, #Fransa, neɣ #Togo ɣur-s Wi-tansa i t-yeɛnan. Tansa-nwen tegber kan 4 n yisekkilen!! D ayen ifessus i usmekti, fessus i uselɣu, yerna tiseddi ines d tin yelhan !\nƐawnet-aɣ ad nessishel aseqdec n umeẓlu agi s uzuzer ines d yimeddukal-nwen.\nTanemmirt nwen.nkent!",
      css_orientation: " text-left ",
    },
  ];

  return (
    <>
      <div className="mt-2 border-t-2 pt-2 ">
        <p
          className={
            "text-sm text-gray-700 leading-loose overflow-auto whitespace-pre-line" +
            list_names[ctx.language].css_orientation
          }
        >
          {/* Parce que, de nos jours, la technologie est plus facilement
          accessible, WiMapper a su en tirer partie pour vous fournir un système
          d'adressage simple, innovant et précis, basé sur des Wi-Adresses.{" "}
          <br />
          C'est quoi une Wi-Adresse ? c'est une adresse composée du nom de votre
          commune et d'un code à 4 caractères. Chaque lieu en #Algérie, en
          #Tunisie, en #France, ou au #Togo possède une Wi-Adresse
          correspondante. Votre adresse tient en seulement 4 caractères !! C'est
          Simple à retenir, Facile à communiquer et avec une Précision inégalée{" "}
          <br />
          Aidez-nous à démocratiser le service en partageant avec vos amis.
          <br />
          Merci ! */}
          {list_names[ctx.language].aboutUs_text}
        </p>
      </div>
    </>
  );
};

export default AboutUs;
