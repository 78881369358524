import L from "leaflet";

const IconWiMapper = (category) => {
  let url = "https://www.WiMapper.com/images/" + category + "_icon.png";
  return new L.Icon({
    iconUrl: url,
    //iconRetinaUrl: require("../../Wimarker.png"),
    // iconAnchor: [40, 50],
    // popupAnchor: null,
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null,
    iconSize: [30, 30],
    //className: "leaflet-div-icon",
  });
};

export { IconWiMapper };
