import React, { useContext } from "react";

import WiAddress from "../../context/WiAddress";

const Localization = (props) => {
  const ctx = useContext(WiAddress);

  const list_names = [
    {
      localization_text1:
        "Pour identifier votre Wi-Adresse, WiMapper a besoin d'avoir accès à votre localisation.\n",
      localization_text2: "OK, j'ai compris !",
      css_orientation: " text-left ",
    },
    {
      localization_text1: "نحن بحاجة إلى الحصول على موقعك لتحديد <وي>عنوانك \n",
      localization_text2: "نعم فهمت",
      css_orientation: " text-right ",
    },
    {
      localization_text1:
        "To identify your Wi-Address, WiMapper needs to have access to your location.\n",
      localization_text2: "OK, I understand !",
      css_orientation: " text-left ",
    },
    {
      localization_text1:
        "Akken ad teẓreḍ Wi-Tansa inek.inem, WiMapper teḥwaj ad tesɛu addaf ar usideg ik.im.\n",
      localization_text2: "Yeṛveḥ, gziɣ !",
      css_orientation: " text-left ",
    },
  ];

  return (
    <>
      <div className="mt-2 border-t-2 pt-2">
        <p className="text-md text-center text-gray-700 leading-loose">
          {list_names[ctx.language].localization_text1}
        </p>
        <div className="flex justify-center mt-6" onClick={props.closeDialog}>
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
            {list_names[ctx.language].localization_text2}
          </button>
        </div>
      </div>
    </>
  );
};

export default Localization;
