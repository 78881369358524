import React, { useContext, useEffect } from "react";
import WiAddress from "../context/WiAddress";

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const AutoCompleteResult = (props) => {
  const ctx = useContext(WiAddress);
  let seprator = ctx.language != 1 ? "," : "،";

  const list_names = [
    {
      address_text1: "Adresse",
      css_direction: "ltr",
    },
    {
      address_text1: "عنوان",
      css_direction: "rtl",
    },
    {
      address_text1: "Address",
      css_direction: "ltr",
    },
    {
      address_text1: "Tansa",
      css_direction: "ltr",
    },
  ];

  const onClickHandler = async () => {
    let response = await fetch(
      ctx.host +
        "/geocoder?address=" +
        props.commune +
        "," +
        props.postal_code +
        "," +
        props.code
    );
    if (response.status !== 200) {
      console.log(
        "Looks like there was a problem. Status Code: " + response.status
      );
      return;
    }
    let data = await response.json();

    //console.log("result: " + data);

    ctx.setCommune(data.properties.name);
    ctx.setCommune_ar(data.properties.name_ar);
    ctx.setCommune_kab(data.properties.name_kab);
    let code_array = data.properties.code;
    let code = "";
    // code_array[0] + code_array[1] + code_array[2] + code_array[3];
    for (let i = 0; i < code_array.length; i++) {
      code += code_array[i];
    }
    //    console.log("code_autocomplete:" + code);
    ctx.setCode(code);
    //    ctx.setCode(data.properties.code);
    ctx.setPostal_code(data.properties.wilaya);
    ctx.setPays(data.properties.pays);
    // ctx.setLatitude(data.geometry.coordinates[1]);
    // ctx.setLongitute(data.geometry.coordinates[0]);
    ctx.setLongLat({
      long: data.geometry.coordinates[0],
      lat: data.geometry.coordinates[1],
    });

    // if code is provided increase the zoom
    /*console.log(
      "autocomplete code: " + props.code + " length: " + props.code.length
    );*/
    if (props.code.length > 0) {
      ctx.setZoom(16);
    } else {
      ctx.setZoom(12);
    }

    let displayed_input = data.properties.name;
    if (ctx.language == 1 && data.properties.name_ar != "") {
      displayed_input = data.properties.name_ar;
    } else if (ctx.language == 3 && data.properties.name_kab != "") {
      displayed_input = data.properties.name_kab;
    }

    props.setInputValue(displayed_input + "," + props.code);
  };

  let html_poi_wi = `<span> ${props.commune} , ${props.code} </span>`;

  let displayed_name = "";

  let displayed_commune = props.commune;

  if (props.poi && props.name) {
    if (ctx.language == 1) {
      if (props.name_ar && props.name_ar != "") {
        displayed_name = props.name_ar;
      } else {
        displayed_name = props.name;
      }
    } else if (ctx.language == 3) {
      if (props.name_kab && props.name_kab != "") {
        displayed_name = props.name_kab;
      } else {
        displayed_name = props.name;
      }
    } else {
      displayed_name = props.name;
    }
  }

  if (ctx.language == 1) {
    if (props.commune_ar && props.commune_ar != "")
      displayed_commune = props.commune_ar;
  } else if (ctx.language == 3) {
    if (props.commune_kab && props.commune_kab != "")
      displayed_commune = props.commune_kab;
  }

  return (
    <li
      className="flex p-2 border-t-2 border-gray-300/50  hover:bg-gray-300 hover:cursor-pointer "
      onClick={onClickHandler}
    >
      {props.poi && (
        <div
          className="flex flex-wrap"
          dir={list_names[ctx.language].css_direction}
        >
          <span className=" px-1">{displayed_name}</span>
          <span>
            {" ("}
            <span className="font-semibold">{displayed_commune}</span>
            {seprator + " "}
            <span className="font-bold text-md text-blue-800" dir="ltr">
              {addSpace(props.code)}
            </span>
            <span>{")"} </span>
          </span>
        </div>
      )}

      {!props.poi && (
        <div
          className="flex flex-wrap"
          dir={list_names[ctx.language].css_direction}
        >
          <span className="px-1">{displayed_commune}</span>
          <span>
            {"(" + props.postal_code + seprator + " " + props.pays + ")"}
          </span>
        </div>
      )}
    </li>
  );
};

export default AutoCompleteResult;
