import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Dialog, Transition } from "@headlessui/react";

// import { ShareSocial } from "react-share-social";

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  ViberShareButton,
  ViberIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import WiAddress from "../context/WiAddress";

const style = {
  //   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 30px",
  //   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
};

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const ShareBox = (props) => {
  const [copyText, setCopyText] = useState(false);

  const [commune, setCommune] = useState("");
  const [commune_ar, setCommune_ar] = useState("");
  const [commune_kab, setCommune_kab] = useState("");
  const [displayedCommune, setDisplayedCommune] = useState("");
  const [seprator, setSeparator] = useState("");

  const [code, setCode] = useState("");
  const [postal_code, setPostal_code] = useState("");

  const ctx = useContext(WiAddress);

  const list_names = [
    {
      share_text1: "Partagez !",
      share_text2: "Adresse",
      share_text3: "Copié !",
      share_text4: "Copier",
      share_text5: "Voici ma Wi-Adresse",
      share_text6: "Wi-Adresse: ",
      css_orientation: " text-center ",
      css_direction: "ltr",
    },
    {
      share_text1: "شارك",
      share_text2: "عنوان",
      share_text3: "نسخ !",
      share_text4: "نسخ",
      share_text5: "ها هو <وي> عنواني",
      share_text6: "وي عنوان :",
      css_orientation: " text-center ",
      css_direction: "rtl",
    },
    {
      share_text1: "Share !",
      share_text2: "Address",
      share_text3: "Copied !",
      share_text4: "Copy",
      share_text5: "Here is my Wi-Address",
      share_text6: "Wi-Address: ",
      css_orientation: " text-center ",
      css_direction: "ltr",
    },
    {
      share_text1: "Zuzret !",
      share_text2: "Tansa",
      share_text3: "Yettwanɣel !",
      share_text4: "Nɣel",
      share_text5: "Ayagi d Wi-Tansa inu",
      share_text6: "Wi-Tansa: ",
      css_orientation: " text-center ",
      css_direction: "ltr",
    },
  ];

  useEffect(() => {
    if (props.showPoiVal) {
      setCommune(ctx.poiWiAddressTown);
      setCommune_ar(ctx.poiWiAddressTown_ar);
      setCommune_kab(ctx.poiWiAddressTown_kab);
      setPostal_code(ctx.poiWiAddressPostalCode);
      setCode(ctx.poiWiAddressCode);

      if (ctx.language == 1) {
        setSeparator("،");
        if (ctx.poiWiAddressTown_ar && ctx.poiWiAddressTown_ar != "") {
          setDisplayedCommune(ctx.poiWiAddressTown_ar);
        } else {
          setDisplayedCommune(ctx.poiWiAddressTown);
        }
      } else if (ctx.language == 3) {
        setSeparator(",");
        if (ctx.poiWiAddressTown_kab && ctx.poiWiAddressTown_kab != "") {
          setDisplayedCommune(ctx.poiWiAddressTown_kab);
        } else {
          setDisplayedCommune(ctx.poiWiAddressTown);
        }
      } else {
        setSeparator(",");
        setDisplayedCommune(ctx.poiWiAddressTown);
      }
    } else {
      setCommune(ctx.commune);
      setCommune_ar(ctx.commune_ar);
      setCommune_kab(ctx.commune_kab);
      setPostal_code(ctx.postal_code);
      setCode(ctx.code);
      if (ctx.language == 1) {
        setSeparator("،");
        if (ctx.commune_ar && ctx.commune_ar != "") {
          setDisplayedCommune(ctx.commune_ar);
        } else {
          setDisplayedCommune(ctx.commune);
        }
      } else if (ctx.language == 3) {
        setSeparator(",");
        if (ctx.commune_kab && ctx.commune_kab != "") {
          setDisplayedCommune(ctx.commune_kab);
        } else {
          setDisplayedCommune(ctx.commune);
        }
      } else {
        setSeparator(",");
        setDisplayedCommune(ctx.commune);
      }
    }
  }, [
    props.showPoiVal,
    ctx.poiWiAddressTown,
    ctx.poiWiAddressTown_ar,
    ctx.poiWiAddressTown_kab,
    ctx.poiWiAddressPostalCode,
    ctx.poiWiAddressCode,
    ctx.commune,
    ctx.commune_ar,
    ctx.commune_kab,
    ctx.postal_code,
    ctx.code,
    ctx.language,
  ]);

  const shareLink = encodeURI(
    "https://www.wimapper.com/?address=" +
      commune +
      "/" +
      postal_code +
      "/" +
      code
  );

  //add left to right enbedding U+202A
  const wiAdrText =
    list_names[ctx.language].share_text6 +
    "\n" +
    displayedCommune +
    seprator +
    " ‎" +
    addSpace(code) +
    "\n";

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setCopyText(true);
    });
  });

  let bgCopyColor = copyText === true ? "bg-blue-200" : "";
  let cpText =
    copyText === true
      ? list_names[ctx.language].share_text3
      : list_names[ctx.language].share_text4;

  useEffect(() => {
    setCopyText(false);
  }, [commune, code]);

  let content = (
    <div className="flex justify-center flex-wrap mt-2">
      {/* <ShareSocial
        style={style}
        url="url_to_share.com"
        socialTypes={["facebook", "twitter", "reddit", "linkedin"]}
      /> */}
      {/* <FacebookShareButton
        url={shareLink}
        quote={wiAdrText}
        hashtag="#WiMapper"
      >
        <FacebookIcon size={50} round={true} />
      </FacebookShareButton>

      <FacebookMessengerShareButton
        url={shareLink}
        quote={wiAdrText}
        hashtag="#WiMapper"
      >
        <FacebookMessengerIcon size={50} round={true} />
      </FacebookMessengerShareButton> */}

      <div className="mx-1">
        <WhatsappShareButton
          url={shareLink}
          title={wiAdrText}
          separator={"\r\n"}
        >
          <WhatsappIcon size={42} round={true} />
        </WhatsappShareButton>
      </div>
      <div className="mx-1">
        <ViberShareButton url={shareLink} title={wiAdrText} separator={"\r\n"}>
          <ViberIcon size={42} round={true} />
        </ViberShareButton>
      </div>
      <div className="mx-1">
        <TwitterShareButton
          url={shareLink}
          title={wiAdrText}
          hashtag="#WiMapper"
        >
          <XIcon size={42} round={true} />
        </TwitterShareButton>
      </div>

      {/* <LinkedinShareButton
        url={shareLink}
        quote={wiAdrText}
        hashtag="#WiMapper"
      >
        <LinkedinIcon size={50} round={true} />
      </LinkedinShareButton> */}

      <div className="mx-1">
        <EmailShareButton
          url={shareLink}
          body={wiAdrText}
          subject={list_names[ctx.language].share_text5}
          separator={"\r\n"}
        >
          <EmailIcon size={42} round={true} />
        </EmailShareButton>
      </div>
    </div>
  );

  return (
    <Transition appear show={props.showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={props.closeDialog}
        disableportal="true"
      >
        <div className="min-h-screen px-2 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md  my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-gray-100 border-2">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                <div className="flex justify-between p-4 border-b-2">
                  <div></div>
                  <div tabIndex="-1">
                    {list_names[ctx.language].share_text1}
                  </div>
                  <div
                    className="h-6 w-6 border-2 rounded-md border-white hover:border-gray-500 hover:cursor-pointer flex"
                    onClick={props.closeDialog}
                  >
                    <svg
                      className="m-auto"
                      width="16"
                      height="16"
                      viewBox="0 0 10 10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.728 4.738L9.475.99 8.485 0 4.738 3.748.99 0 0 .99l3.748 3.748L0 8.485l.99.99 3.748-3.747 3.747 3.747.99-.99-3.747-3.747z"
                        fill="#999"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Dialog.Title>
              <div className="" dir={list_names[ctx.language].css_direction}>
                <div className="flex justify-center bg-green-700">
                  <span className="font-semibold text-xl border-2 rounded-lg border-green-700 bg-green-700 text-white px-4">
                    {/* <span className="font-PermanentMarker pr-1">Wi</span>Adresse */}
                    <div className="flex justify-center">
                      <div>
                        <img src="../../wi.png" height="25px" width="25px" />
                      </div>
                      <div className="mx-1" tabIndex="-1">
                        {list_names[ctx.language].share_text2}
                      </div>
                    </div>
                  </span>
                </div>
                <div className="flex justify-center mt-2 ">
                  <span
                    className="flex font-semibold p-1 text-lg "
                    tabIndex="-1"
                  >
                    {displayedCommune}
                    {seprator}{" "}
                    <span
                      className="font-bold text-md text-blue-800 tracking-widest px-1"
                      dir="ltr"
                    >
                      {addSpace(code)}
                    </span>
                  </span>
                  <button
                    type="button"
                    className={`${bgCopyColor} inline-flex justify-center max-h-10 ml-2 px-4 py-2 text-sm font-medium text-blue-900  rounded-md hover:bg-blue-200 focus:outline-none border-2`}
                    onClick={onCopy}
                    autoFocus
                  >
                    {cpText}
                  </button>
                </div>
                {content}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareBox;
