import React, { useState } from "react";

const Address = {
  commune: "",
  code: "",
  pays: "",
  postal_code: "",
  //   longitute: "",
  //   latitude: "",
  longlat: { long: "", lat: "" },
  zoom: "",
  host: "",
  language: "",

  showPoiDialog: false,
  poiName: "",
  poiName_ar: "",
  poiName_kab: "",
  poiCategory: "",
  poiImage: "",
  poiPhoneNumber: "",
  poiEmail: "",
  poiWebsite: "",
  poiWiAddressTown: "",
  poiWiAddressTown_ar: "",
  poiWiAddressTown_kab: "",
  poiWiAddressPostalCode: "",
  poiWiAddressCode: "",
  poiWiAddressCountry: "",
  PoiLonglat: {},

  poiFilterCategory: "",
  poiFilterDayNight: "1",
  poiFilterDate: "",

  setCommune: () => {},
  setCode: () => {},
  setPays: () => {},
  setPostal_code: () => {},
  //   setLongitute: () => {},
  //   setLatitude: () => {},
  setLongLat: () => {},
  setZoom: () => {},
  setLanguage: () => {},
  setShowPoiDialog: () => {},
  setPoiName: () => {},
  setPoiName_ar: () => {},
  setPoiName_kab: () => {},
  setPoiCategory: () => {},
  setPoiImage: () => {},
  setPoiPhoneNumber: () => {},
  setPoiEmail: () => {},
  setPoiWebsite: () => {},
  setPoiWiAddressTown: () => {},
  setPoiWiAddressTown_ar: () => {},
  setPoiWiAddressTown_kab: () => {},
  setPoiWiAddressPostalCode: () => {},
  setPoiWiAddressCode: () => {},
  setPoiWiAddressCountry: () => {},
  setPoiLongLat: () => {},
  setPoiFilterCategory: () => {},
  setPoiFilterDayNight: () => {},
  setPoiFilterDate: () => {},
};

const WiAddress = React.createContext(Address);

export const WiAddressProvider = (props) => {
  const [commune, setCommune] = useState("");
  const [commune_ar, setCommune_ar] = useState("");
  const [commune_kab, setCommune_kab] = useState("");
  const [code, setCode] = useState("");
  const [pays, setPays] = useState("");
  const [postal_code, setPostal_code] = useState("");
  //to be adapted
  //   const [longitude, setLongitute] = useState(-0.09);
  //   const [latitude, setLatitude] = useState(51.505);

  const [longlat, setLongLat] = useState({
    long: 4.034543036977994,
    lat: 36.70982219645621,
  });

  const [zoom, setZoom] = useState(5);
  const [language, setLanguage] = useState(2);

  // POI related data
  const [showPoiDialog, setShowPoiDialog] = useState(false);

  const [poiName, setPoiName] = useState("");
  const [poiName_ar, setPoiName_ar] = useState("");
  const [poiName_kab, setPoiName_kab] = useState("");
  const [poiCategory, setPoiCategory] = useState("");
  const [poiImage, setPoiImage] = useState("");
  const [poiPhoneNumber, setPoiPhoneNumber] = useState("");
  const [poiEmail, setPoiEmail] = useState("");
  const [poiWebsite, setPoiWebsite] = useState("");

  const [poiWiAddressTown, setPoiWiAddressTown] = useState("");
  const [poiWiAddressTown_ar, setPoiWiAddressTown_ar] = useState("");
  const [poiWiAddressTown_kab, setPoiWiAddressTown_kab] = useState("");
  const [poiWiAddressPostalCode, setPoiWiAddressPostalCode] = useState("");
  const [poiWiAddressCode, setPoiWiAddressCode] = useState("");
  const [poiWiAddressCountry, setPoiWiAddressCountry] = useState("");

  const [PoiLonglat, setPoiLongLat] = useState({
    long: 4.034543036977994,
    lat: 36.70982219645621,
  });

  const [poiFilterCategory, setPoiFilterCategory] = useState("");
  const [poiFilterDayNight, setPoiFilterDayNight] = useState("1");
  const [poiFilterDate, setPoiFilterDate] = useState("");

  return (
    <WiAddress.Provider
      value={{
        commune: commune,
        commune_ar: commune_ar,
        commune_kab: commune_kab,
        code: code,
        pays: pays,
        postal_code: postal_code,
        // longitute: longitude,
        // latitude: latitude,
        longlat: longlat,
        zoom: zoom,
        language: language,
        host: "https://www.wimapper.com/api/services",
        // host: "http://localhost:4000/api/services",
        // host_poi: "http://localhost:4000/api/poi",
        host_poi: "https://www.wimapper.com/api/poi",
        showPoiDialog: showPoiDialog,
        poiName: poiName,
        poiName_ar: poiName_ar,
        poiName_kab: poiName_kab,
        poiCategory: poiCategory,
        poiImage: poiImage,
        poiPhoneNumber: poiPhoneNumber,
        poiEmail: poiEmail,
        poiWebsite: poiWebsite,
        poiWiAddressTown: poiWiAddressTown,
        poiWiAddressTown_ar: poiWiAddressTown_ar,
        poiWiAddressTown_kab: poiWiAddressTown_kab,
        poiWiAddressPostalCode: poiWiAddressPostalCode,
        poiWiAddressCode: poiWiAddressCode,
        poiWiAddressCountry: poiWiAddressCountry,
        PoiLonglat: PoiLonglat,
        poiFilterCategory: poiFilterCategory,
        poiFilterDayNight: poiFilterDayNight,
        poiFilterDate: poiFilterDate,

        setCommune: setCommune,
        setCommune_ar: setCommune_ar,
        setCommune_kab: setCommune_kab,
        setCode: setCode,
        setPays: setPays,
        setPostal_code: setPostal_code,
        // setLongitute: setLongitute,
        // setLatitude: setLatitude,
        setLongLat: setLongLat,
        setZoom: setZoom,
        setLanguage: setLanguage,
        setShowPoiDialog: setShowPoiDialog,
        setPoiName: setPoiName,
        setPoiName_ar: setPoiName_ar,
        setPoiName_kab: setPoiName_kab,
        setPoiCategory: setPoiCategory,
        setPoiImage: setPoiImage,
        setPoiPhoneNumber: setPoiPhoneNumber,
        setPoiEmail: setPoiEmail,
        setPoiWebsite: setPoiWebsite,
        setPoiWiAddressTown: setPoiWiAddressTown,
        setPoiWiAddressTown_ar: setPoiWiAddressTown_ar,
        setPoiWiAddressTown_kab: setPoiWiAddressTown_kab,
        setPoiWiAddressPostalCode: setPoiWiAddressPostalCode,
        setPoiWiAddressCode: setPoiWiAddressCode,
        setPoiWiAddressCountry: setPoiWiAddressCountry,
        setPoiLongLat: setPoiLongLat,
        setPoiFilterCategory: setPoiFilterCategory,
        setPoiFilterDayNight: setPoiFilterDayNight,
        setPoiFilterDate: setPoiFilterDate,
      }}
    >
      {props.children}
    </WiAddress.Provider>
  );
};

export default WiAddress;
