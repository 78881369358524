import React, { useContext, useState, useEffect } from "react";

import WiAddress from "../../context/WiAddress";

const Tutorial = (props) => {
  const ctx = useContext(WiAddress);
  const [linkVideo, setlinkVideo] = useState(
    "https://www.youtube.com/embed/0QMqDtma8Oc"
  );

  useEffect(() => {
    if (ctx.language == 1) {
      setlinkVideo("https://www.youtube.com/embed/BZqG79GHAe0");
    } else {
      setlinkVideo("https://www.youtube.com/embed/0QMqDtma8Oc");
    }
  }, [ctx.language]);

  return (
    <iframe
      title="WiMapper"
      width="100%"
      height="315"
      src={linkVideo}
      frameBorder=" 0"
      allowFullScreen
    ></iframe>
  );
};

export default Tutorial;
