import L from "leaflet";

const WiIcon = new L.Icon({
  iconUrl: require("../../Wimarker.png"),
  //iconRetinaUrl: require("../../Wimarker.png"),
  iconAnchor: [40, 50],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [80, 50],
  //className: "leaflet-div-icon",
});

export { WiIcon };
