import React, { useContext } from "react";

import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  LayerGroup,
} from "react-leaflet";

import Markers from "./Markers";
import WiAddress from "../../context/WiAddress";

const WiMap = () => {
  const ctx = useContext(WiAddress);

  const polyline = [
    [27.67296489424534, -13.17476610624403],
    [27.668172917429146, -8.670223441902593],
  ];

  const limeOptions = { color: "#c5b1c0", dashArray: "5,4" };

  const list_names = [
    {
      map: "Map",
      satellite: "Satellite",
    },
    {
      map: "خريطة",
      satellite: "خريطة فضائية",
    },
    {
      map: "Map",
      satellite: "Satellite",
    },
    {
      map: "Takerḍa",
      satellite: "Agensa",
    },
  ];

  const base_arabic = ctx.language == 1 ? true : false;

  //console.log("Draw WiMap");

  return (
    <div className="h-full w-full">
      <MapContainer
        center={[ctx.longlat.lat, ctx.longlat.long]}
        zoom={ctx.zoom}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{ height: "100%", width: "100%" }}
      >
        <LayersControl position="topleft">
          {ctx.language == 1 && (
            <>
              <LayersControl.BaseLayer
                checked
                name={list_names[ctx.language].map}
              >
                <LayerGroup>
                  <TileLayer
                    attribution="OSM - WiMapper"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    className="toBeInverted"
                  ></TileLayer>
                  <Polyline pathOptions={limeOptions} positions={polyline} />
                </LayerGroup>
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer
                name={list_names[ctx.language].satellite}
              >
                <TileLayer
                  attribution="ESRI - WiMapper"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
              </LayersControl.BaseLayer>
            </>
          )}
          {ctx.language == 3 && (
            <>
              <LayersControl.BaseLayer
                checked
                name={list_names[ctx.language].map}
              >
                <LayerGroup>
                  <TileLayer
                    attribution="OSM - WiMapper"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    className="toBeInverted"
                  ></TileLayer>
                  <Polyline pathOptions={limeOptions} positions={polyline} />
                </LayerGroup>
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer
                name={list_names[ctx.language].satellite}
              >
                <TileLayer
                  attribution="ESRI - WiMapper"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
              </LayersControl.BaseLayer>
            </>
          )}
          {!(ctx.language == 1) && !(ctx.language == 3) && (
            <>
              <LayersControl.BaseLayer
                checked
                name={list_names[ctx.language].map}
              >
                <LayerGroup>
                  <TileLayer
                    attribution="OSM - WiMapper"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    className="toBeInverted"
                  ></TileLayer>
                  <Polyline pathOptions={limeOptions} positions={polyline} />
                </LayerGroup>
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer
                name={list_names[ctx.language].satellite}
              >
                <TileLayer
                  attribution="ESRI - WiMapper"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
              </LayersControl.BaseLayer>
            </>
          )}
        </LayersControl>

        <Markers></Markers>
      </MapContainer>
    </div>
  );
};

export default WiMap;
