import React, { useMemo, useRef, useContext, useEffect } from "react";

import { Marker, useMap, Popup, Tooltip } from "react-leaflet";
import WiAddress from "../../context/WiAddress";

import { WiIcon } from "./WiIcon";
import WiPoi from "./WiPoi";

const list_names = [
  {
    marker_message: "Déplacer le Marqueur",
  },
  {
    marker_message: "حرك المؤشر إلى الموقع المطلوب",
  },
  {
    marker_message: "Move Marker Position",
  },
  {
    marker_message: "Sembiwel ticreḍt ",
  },
];

const addSpace = (str, language) => {
  // console.log("wimarker str:" + str);
  if (str != "") return str.split("").join(".");
  else return list_names[language].marker_message;
};

const WiMarker = (props) => {
  const draggable = true;

  const map = useMap();

  const markerRef = useRef(null);

  const ctx = useContext(WiAddress);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          //          setPosition(marker.getLatLng());
          let markerLatLng = marker.getLatLng();
          //console.log("Marker Dragend :" + markerLatLng);

          // ctx.setLatitude(markerLatLng.lat);
          // ctx.setLongitute(markerLatLng.lng);

          // ctx.setLongLat({ long: markerLatLng.lng, lat: markerLatLng.lat });

          ctx.setZoom(map.getZoom());

          // map.flyTo(
          //   marker.getLatLng(),
          //   //  18
          //   map.getZoom()
          // );

          //http request to get Wi-Address
          fetch(
            ctx.host +
              "/reverseGeocode/" +
              markerLatLng.lat +
              "," +
              markerLatLng.lng
          )
            .then((response) => {
              if (response.status !== 200) {
                console.log(
                  "Looks like there was a problem. Status Code: " +
                    response.status
                );
                return;
              }

              // Examine the text in the response
              response.json().then((data) => {
                //console.log(data);
                //console.log("Commune: " + data.properties.name);
                //console.log("Code: " + data.properties.code);
                //console.log("Postal code: " + data.properties.wilaya);
                let code_array = data.properties.code;
                // let code =
                //   code_array[0] +
                //   code_array[1] +
                //   code_array[2] +
                //   code_array[3] +
                //   code_array[4];

                let code = "";
                for (let i = 0; i < code_array.length; i++) {
                  code += code_array[i];
                }
                //console.log("Code: " + code);
                ctx.setCommune(data.properties.name);
                ctx.setCommune_ar(data.properties.name_ar);
                ctx.setCommune_kab(data.properties.name_kab);
                ctx.setCode(code);
                ctx.setPostal_code(data.properties.wilaya);
                ctx.setPays(data.properties.pays);

                ctx.setLongLat({
                  long: data.geometry.coordinates[0],
                  lat: data.geometry.coordinates[1],
                });
              });
            })
            .catch(function (err) {
              //console.log("Fetch Error :-S", err);
            });
        }
      },
    }),
    []
  );

  //console.log("Draw WiMarker");

  useEffect(() => {
    //console.log("useEffect on Map + zoom= " + ctx.zoom);
    map.flyTo([ctx.longlat.lat, ctx.longlat.long], ctx.zoom);

    console.log("long lat " + ctx.longlat.long + " " + ctx.longlat.lat);

    //http request to get Wi-Address
    // fetch(
    //   ctx.host + "/reverseGeocode/" + ctx.longlat.lat + "," + ctx.longlat.long
    // )
    //   .then((response) => {
    //     if (response.status !== 200) {
    //       //console.log(
    //         "Looks like there was a problem. Status Code: " + response.status
    //       );
    //       return;
    //     }

    //     // Examine the text in the response
    //     response.json().then((data) => {
    //       //console.log(data);
    //       //console.log("Commune: " + data.properties.name);
    //       //console.log("Code: " + data.properties.code);
    //       //console.log("Postal code: " + data.properties.wilaya);
    //       let code_array = data.properties.code;
    //       let code =
    //         code_array[0] + code_array[1] + code_array[2] + code_array[3];
    //       //console.log("Code: " + code);
    //       ctx.setCommune(data.properties.name);
    //       ctx.setCode(code);
    //       ctx.setPostal_code(data.properties.wilaya);
    //     });
    //   })
    //   .catch(function (err) {
    //     //console.log("Fetch Error :-S", err);
    //   });
  }, [ctx.longlat, ctx.zoom]);

  return (
    <>
      {/* Main Marker */}
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={[ctx.longlat.lat, ctx.longlat.long]}
        ref={markerRef}
        icon={WiIcon}
      >
        <Tooltip direction="top" offset={[0, -50]} opacity={1} permanent>
          <span className="font-semibold text-sm text-blue-800 ">
            {addSpace(ctx.code, ctx.language)}
            {/* {ctx.code} */}
          </span>
        </Tooltip>
      </Marker>
    </>
  );
};

export default WiMarker;
