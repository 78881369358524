import React, { useContext, useState, useEffect, useCallback } from "react";
import PalestineFlag from "../pray_for_palestine.png";
import WiAddress from "../context/WiAddress";

const PoiFilter = (props) => {
  const ctx = useContext(WiAddress);

  //Lime-600
  let color_1 = "#65a30d";
  //Green WiMapper
  let color_2 = "#15803d";
  //Red 800
  let color_3 = "#991b1b";
  //Sky 800
  let color_4 = "#075985";
  //White
  let color_5 = "#ffffff";
  //Black
  let color_6 = "#000000";
  //Indigo 900
  let color_7 = "#312e81";

  const [showPoiFilter, setShowPoiFilter] = useState(false);

  const [phGarde, setPhGarde] = useState(false);

  const [pharmacies, setPharmacies] = useState(false);

  const [restaurants, setRestaurants] = useState(false);

  const [commerces, setCommerces] = useState(false);

  const [services, setServices] = useState(false);

  const list_names = [
    {
      ph_garde_1: "Ph. Garde",
      pharmacies: "Pharmacies",
      restaurants: "Restaurants",
      shops: "Commerces",
      services: "Services",
      css_orientation: " text-left ",
    },
    {
      ph_garde_1: "ص. المناوبة",
      pharmacies: "صيدليات",
      restaurants: "مطاعم",
      shops: "محلات",
      services: "خدمات",
      css_orientation: " text-right ",
    },
    {
      ph_garde_1: "On-call Ph",
      pharmacies: "Pharmacies",
      restaurants: "Restaurants",
      shops: "Shopping",
      services: "Services",
      css_orientation: " text-left ",
    },
    // to be translated to tamazight
    {
      ph_garde_1: "tin s nnuba",
      pharmacies: "Tifarmasin",
      restaurants: "Isečča",
      shops: "Tiḥuna",
      services: "Imeẓla",
      css_orientation: " text-left ",
    },
  ];

  useEffect(() => {
    if (ctx.postal_code == "1501" || ctx.postal_code == "0501") {
      setShowPoiFilter(true);
    } else {
      setShowPoiFilter(false);
      setPhGarde(false);
      setPharmacies(false);
      setRestaurants(false);
      setCommerces(false);
      setServices(false);

      ctx.setPoiFilterCategory("");
      ctx.setPoiFilterDate("");
    }
  }, [ctx.postal_code]);

  useEffect(() => {}, [phGarde, pharmacies, restaurants, commerces]);

  const onClickPhGarde = useCallback((event) => {
    setPharmacies(false);
    setRestaurants(false);
    setCommerces(false);
    setServices(false);

    // test number of on pharmacies and retreive the geolocation of the one that has the same postal code (if there is only one)
    // use the position of that pharmacy to navigate into it (for night on call pharm)
    // Also identify the date based on the time

    if (phGarde == true) {
      ctx.setPoiFilterCategory("");
      ctx.setPoiFilterDate("");
      //trigger map event
      ctx.setLongLat({
        long: ctx.longlat.long,
        lat: ctx.longlat.lat,
      });
    } else {
      ctx.setPoiFilterCategory("pharmacy");

      let today = new Date();
      //.toISOString().slice(0, 10);

      let minuit = new Date();
      minuit.setHours(0, 0, 0); // 0.00
      let sevenTwentyNine = new Date();
      sevenTwentyNine.setHours(7, 29, 59); // 7.29.59
      let sevenPM = new Date();
      sevenPM.setHours(19, 0, 0); // 19.00
      let justBeforeMidnight = new Date();
      justBeforeMidnight.setHours(23, 59, 59); // 23.59.59

      // console.log(today);

      // set right date for ctx.poiFilterDate based on the hour 0h00 -> 7h29 -> 23h59

      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      // sevenTwentyNine.setHours(22, 29, 59); // 7.29.59
      //befor 7h29, choose the previous day
      if (today < sevenTwentyNine) {
        dd = (parseInt(dd) - 1).toString();
      }

      let date = yyyy + "-" + mm + "-" + dd;

      // console.log(date);

      ctx.setPoiFilterDate(date);

      let endpoint =
        ctx.host_poi +
        "/getPOI/" +
        ctx.longlat.lat +
        "," +
        ctx.longlat.long +
        "," +
        "12" +
        "," +
        "7000" +
        "," +
        "pharmacy";

      // get night on call pharms
      fetch(endpoint + "," + "1" + "," + date)
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          }

          let lat = ctx.longlat.lat;
          let long = ctx.longlat.long;
          let data_name = "";
          let data_name_ar = "";
          let data_code = "";
          let data_postalCode = "";
          let data_country = "";

          response.json().then((data) => {
            data.map((poi) => {
              //If POI postal code is equal to the one of the commune, keep the information of the POI
              if (poi.properties.wiAddress.postalCode == ctx.postal_code) {
                ctx.setPoiName(poi.properties.name);
                ctx.setPoiName_ar(poi.properties.name_ar);
                ctx.setPoiCategory(poi.properties.category);
                ctx.setPoiImage(poi.properties.image);
                ctx.setPoiPhoneNumber(poi.properties.phoneNumber);
                ctx.setPoiEmail(poi.properties.email);
                ctx.setPoiWebsite(poi.properties.website);
                ctx.setPoiWiAddressTown(poi.properties.wiAddress.town);
                ctx.setPoiWiAddressTown_ar(poi.properties.wiAddress.town_ar);
                ctx.setPoiWiAddressPostalCode(
                  poi.properties.wiAddress.postalCode
                );
                ctx.setPoiWiAddressCode(poi.properties.wiAddress.code);
                ctx.setPoiWiAddressCountry(poi.properties.wiAddress.country);
                ctx.setPoiLongLat({
                  lat: poi.geometry.coordinates[1],
                  long: poi.geometry.coordinates[0],
                });

                data_name = poi.properties.wiAddress.town;
                data_name_ar = poi.properties.wiAddress.town_ar;
                data_code = poi.properties.wiAddress.code;
                data_postalCode = poi.properties.wiAddress.postalCode;
                data_country = poi.properties.wiAddress.country;

                lat = poi.geometry.coordinates[1];
                long = poi.geometry.coordinates[0];
              }
            });

            //compute if it is day or night. Day is true if hour > 7h29 and < 19h
            let day = false;

            if (today > sevenTwentyNine && today < sevenPM) {
              // console.log("Day!");
              day = true;
            }

            // if night and there is at least one On-Call pharm
            if (!day) {
              if (data_name != "" && data_code != "" && data_postalCode != "") {
                ctx.setPoiFilterDayNight(1);

                //move Map
                ctx.setCommune(data_name);
                ctx.setCommune_ar(data_name_ar);
                let code_array = data_code;
                let code = "";
                // code_array[0] + code_array[1] + code_array[2] + code_array[3];
                for (let i = 0; i < code_array.length; i++) {
                  code += code_array[i];
                }
                //      console.log("code_searche:" + code);
                ctx.setCode(code);
                ctx.setPostal_code(data_postalCode);
                ctx.setPays(data_country);
                ctx.setLongLat({
                  long: long,
                  lat: lat,
                });
                ctx.setZoom(16);

                setTimeout(() => {
                  ctx.setShowPoiDialog(true);
                }, 3000);
              }
            }
            // if day
            else {
              // get day on call pharms
              fetch(endpoint + "," + "0" + "," + date)
                .then((response) => {
                  if (response.status !== 200) {
                    console.log(
                      "Looks like there was a problem. Status Code: " +
                        response.status
                    );
                    return;
                  }

                  response.json().then((data) => {
                    // no Day on call pharm, display Night
                    if (data.length == 0) {
                      if (
                        data_name != "" &&
                        data_code != "" &&
                        data_postalCode != ""
                      ) {
                        ctx.setPoiFilterDayNight(1);

                        //move Map
                        ctx.setCommune(data_name);
                        ctx.setCommune_ar(data_name_ar);
                        let code_array = data_code;
                        let code = "";
                        // code_array[0] + code_array[1] + code_array[2] + code_array[3];
                        for (let i = 0; i < code_array.length; i++) {
                          code += code_array[i];
                        }
                        //      console.log("code_searche:" + code);
                        ctx.setCode(code);
                        ctx.setPostal_code(data_postalCode);
                        ctx.setPays(data_country);
                        ctx.setLongLat({
                          long: long,
                          lat: lat,
                        });
                        ctx.setZoom(16);

                        setTimeout(() => {
                          ctx.setShowPoiDialog(true);
                        }, 3000);
                      }
                    }
                    // existing Day on call pharms
                    else {
                      ctx.setPoiFilterDayNight(0);
                      //trigger map event
                      ctx.setLongLat({
                        long: ctx.longlat.long,
                        lat: ctx.longlat.lat,
                      });
                    }
                  });
                })
                .catch(function (err) {
                  //console.log("Fetch Error :-S", err);
                });
            }
          });
        })
        .catch(function (err) {
          //console.log("Fetch Error :-S", err);
        });
    }

    setPhGarde(!phGarde);
  });

  const onClickPharmacies = useCallback((event) => {
    setPhGarde(false);
    setRestaurants(false);
    setCommerces(false);
    setServices(false);
    ctx.setPoiFilterDate("");
    if (pharmacies == true) {
      ctx.setPoiFilterCategory("");
    } else {
      ctx.setPoiFilterCategory("pharmacy");
    }
    //trigger map event
    ctx.setLongLat({
      long: ctx.longlat.long,
      lat: ctx.longlat.lat,
    });
    setPharmacies(!pharmacies);
  });

  const onClickRestaurants = useCallback((event) => {
    setPhGarde(false);
    setPharmacies(false);
    setCommerces(false);
    setServices(false);
    ctx.setPoiFilterDate("");
    if (restaurants == true) {
      ctx.setPoiFilterCategory("");
    } else {
      ctx.setPoiFilterCategory("restaurant");
    }
    //trigger map event
    ctx.setLongLat({
      long: ctx.longlat.long,
      lat: ctx.longlat.lat,
    });
    setRestaurants(!restaurants);
  });

  const onClickCommerces = useCallback((event) => {
    setPhGarde(false);
    setPharmacies(false);
    setRestaurants(false);
    setServices(false);
    ctx.setPoiFilterDate("");
    if (commerces == true) {
      ctx.setPoiFilterCategory("");
    } else {
      ctx.setPoiFilterCategory("store");
    }
    //trigger map event
    ctx.setLongLat({
      long: ctx.longlat.long,
      lat: ctx.longlat.lat,
    });
    setCommerces(!commerces);
  });

  const onClickServices = useCallback((event) => {
    setPhGarde(false);
    setPharmacies(false);
    setRestaurants(false);
    setCommerces(false);
    ctx.setPoiFilterDate("");
    if (services == true) {
      ctx.setPoiFilterCategory("");
    } else {
      ctx.setPoiFilterCategory("services");
    }
    //trigger map event
    ctx.setLongLat({
      long: ctx.longlat.long,
      lat: ctx.longlat.lat,
    });
    setServices(!services);
  });

  return (
    <>
      {/* <div className="mt-2 border-t-2 pt-2">
        <p className="text-md text-center text-gray-700 leading-loose">
          {list_names[ctx.language].localization_text1}
        </p>
      </div> */}
      {!showPoiFilter && (
        <div className="flex justify-end w-full">
          <img className="h-12 my-auto  z-10" src={PalestineFlag} />
        </div>
      )}
      {showPoiFilter && (
        <div className="flex  overflow-x-auto scrollbar-hide space-x-1 z-10   h-8  ml-20 ">
          <div
            className={
              (phGarde ? "bg-green-800" : "bg-white") +
              " flex px-1 border-2 border-gray-300 hover:border-lime-600 hover:cursor-pointer  rounded-xl "
            }
            onClick={onClickPhGarde}
          >
            <span className="my-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={phGarde ? color_5 : color_2}
              >
                <path d="M437.69-280h84.62v-100h100v-84.61h-100v-100h-84.62v100h-100V-380h100v100ZM292.31-142.31q-29.83 0-51.07-21.24Q220-184.79 220-214.62V-630q0-29.83 21.24-51.07 21.24-21.24 51.07-21.24h375.38q29.83 0 51.07 21.24Q740-659.83 740-630v415.38q0 29.83-21.24 51.07-21.24 21.24-51.07 21.24H292.31Zm-43.08-615.38v-60h461.54v60H249.23Z" />
              </svg>
            </span>
            <span
              className={
                "my-auto mr-1 font-semibold text-sm flex " +
                (phGarde ? "text-white" : "text-black")
              }
            >
              <span className="flex-auto whitespace-nowrap">
                {list_names[ctx.language].ph_garde_1}
              </span>
            </span>
          </div>
          <div
            className={
              (pharmacies ? "bg-green-800" : "bg-white") +
              " flex px-1 border-2 border-gray-300 hover:border-lime-600 hover:cursor-pointer  rounded-xl "
            }
            onClick={onClickPharmacies}
          >
            <span className="my-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={pharmacies ? color_5 : color_2}
              >
                <path d="M437.69-280h84.62v-100h100v-84.61h-100v-100h-84.62v100h-100V-380h100v100ZM292.31-142.31q-29.83 0-51.07-21.24Q220-184.79 220-214.62V-630q0-29.83 21.24-51.07 21.24-21.24 51.07-21.24h375.38q29.83 0 51.07 21.24Q740-659.83 740-630v415.38q0 29.83-21.24 51.07-21.24 21.24-51.07 21.24H292.31Zm-43.08-615.38v-60h461.54v60H249.23Z" />
              </svg>
            </span>
            <span
              className={
                "my-auto mr-1 font-semibold text-sm flex " +
                (pharmacies ? "text-white" : "text-black")
              }
            >
              {list_names[ctx.language].pharmacies}
            </span>
          </div>
          <div
            className={
              (restaurants ? "bg-indigo-900" : "bg-white") +
              " flex px-1 border-2 hover:border-indigo-900 hover:cursor-pointer rounded-xl "
            }
            onClick={onClickRestaurants}
          >
            <span className="my-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={restaurants ? color_5 : color_7}
              >
                <path d="M290-90v-363.69q-48.69-10.54-82.04-49.27-33.34-38.73-33.34-92.42V-870h59.99v274.62H290V-870h60v274.62h55.39V-870h59.99v274.62q0 53.69-33.34 92.42-33.35 38.73-82.04 49.27V-90h-60Zm384.61 0v-320H565.39v-270q0-74.92 48.11-129.96 48.11-55.04 121.11-59.27V-90h-60Z" />
              </svg>
            </span>
            <span
              className={
                "my-auto mr-1 font-semibold text-sm flex " +
                (restaurants ? "text-white" : "text-black")
              }
            >
              {list_names[ctx.language].restaurants}
            </span>
          </div>
          <div
            className={
              (commerces ? "bg-red-800" : "bg-white") +
              " flex px-1 border-2 hover:border-red-700 hover:cursor-pointer rounded-xl "
            }
            onClick={onClickCommerces}
          >
            <span className="my-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={commerces ? color_5 : color_3}
              >
                <path d="M168.46-730v-60h623.08v60H168.46ZM170-170v-240h-44.61v-60l43.07-200h623.08l43.07 200v60H790v240h-60v-240H550v240H170Zm60-60h260v-180H230v180Z" />
              </svg>
            </span>
            <span
              className={
                "my-auto mr-1 font-semibold text-sm flex " +
                (commerces ? "text-white" : "text-black")
              }
            >
              {list_names[ctx.language].shops}
            </span>
          </div>

          <div
            className={
              (services ? "bg-sky-800" : "bg-white") +
              " flex px-1 border-2 hover:border-sky-700 hover:cursor-pointer rounded-xl "
            }
            onClick={onClickServices}
          >
            <span className="my-auto">
              {
                //Car logo
                /* <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill={services ? color_5 : color_4}
            >
              <path d="M233.76-177.69q-46.84 0-79.53-32.76T121.54-290H53.85v-417.69q0-30.31 21-51.31 21-21 51.3-21h544.62v152.31h106.15l129.23 173.08V-290h-72.3q0 46.79-32.79 79.55-32.78 32.76-79.61 32.76t-79.53-32.76q-32.69-32.76-32.69-79.55H346.15q0 46.92-32.78 79.61-32.78 32.7-79.61 32.7Zm.09-60q22 0 37.15-15.16Q286.15-268 286.15-290T271-327.16q-15.15-15.15-37.15-15.15t-37.16 15.15Q181.54-312 181.54-290q0 22 15.15 37.15 15.16 15.16 37.16 15.16Zm487.69 0q22 0 37.15-15.16Q773.85-268 773.85-290t-15.16-37.16q-15.15-15.15-37.15-15.15t-37.16 15.15Q669.23-312 669.23-290q0 22 15.15 37.15 15.16 15.16 37.16 15.16ZM670.77-430H850L746.15-567.69h-75.38V-430Z" />
            </svg> */
              }

              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={services ? color_5 : color_4}
              >
                <path d="M671.13-140.54q-7.21 0-14.4-1.74-7.2-1.75-13.5-6.11l-106.92-62.3q-12.7-7.28-19.51-19.56-6.8-12.28-6.8-26.83v-124.84q0-14.55 6.8-26.83 6.81-12.28 19.51-19.56l106.92-62.3q6.31-4.36 13.52-6.11 7.21-1.74 14.42-1.74t13.93 2.18q6.71 2.18 12.98 5.67L805-428.31q12.74 7.28 20.02 19.56 7.29 12.28 7.29 26.83v124.84q0 14.55-7.29 26.83-7.28 12.28-20.02 19.56l-106.92 62.36q-6.23 3.56-12.99 5.67-6.76 2.12-13.96 2.12ZM100-187.69v-88.93q0-30.3 15.46-54.88 15.46-24.58 43.16-38.04 49.84-24.84 107.69-41.5 57.84-16.65 133.69-16.65h11.69q5.23 0 10.46 1.23-23.23 58.15-19.57 122.42 3.65 64.27 36.65 116.35H100Zm300-304.62q-57.75 0-98.87-41.12Q260-574.56 260-632.31q0-57.75 41.13-98.87 41.12-41.13 98.87-41.13 57.75 0 98.87 41.13Q540-690.06 540-632.31q0 57.75-41.13 98.88-41.12 41.12-98.87 41.12Zm179.85 92.46 91.3 53.08 91.31-53.08-91.31-52.46-91.3 52.46Zm115.15 198 89.61-51.75v-109.48L695-309.69v107.84Zm-137.31-51.61 89.62 52.61v-107.84l-89.62-53v108.23Z" />
              </svg>
            </span>
            <span
              className={
                "my-auto mr-1 font-semibold text-sm flex " +
                (services ? "text-white" : "text-black")
              }
            >
              {list_names[ctx.language].services}
            </span>
          </div>

          {/* <div className="flex px-1 border-2 hover:border-green-600 hover:cursor-pointer rounded-xl bg-white">
          <span className="my-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#008000"
            >
              <path d="M480-295.38q-44.31 0-74.46-30.16-30.16-30.15-30.16-74.46 0-44.31 30.16-74.27 30.15-29.96 74.08-30.35h1.23q22.77 32.54 48.61 62.89 25.85 30.35 53.77 58.88-5.77 37.93-34.54 62.7-28.77 24.77-68.69 24.77ZM172.31-100Q142-100 121-121q-21-21-21-51.31v-455.38Q100-658 121-679q21-21 51.31-21h122.15l74-80H410q-3.46 14.39-5.19 29.19-1.73 14.81-1.73 30.81 0 42.62 11.73 81.89 11.73 39.26 31.81 76.96-56.93 11.3-94.08 56.15-37.15 44.85-37.15 105 0 68.85 47.88 116.73 47.88 47.88 116.73 47.88 51.69 0 92.5-28.11 40.81-28.12 59.35-72.96 22.69 21.69 45.07 41.38 22.39 19.69 43.08 37.77 36.23-31.15 71.42-62.84 35.19-31.7 68.58-65.31v213.15Q860-142 839-121q-21 21-51.31 21H172.31ZM720-374.23Q843.69-483 896.46-562.15q52.77-79.16 52.77-151.85 0-108-69.23-171.61-69.23-63.62-160-63.62t-160 63.62Q490.77-822 490.77-714q0 72.69 52.77 151.85Q596.31-483 720-374.23Zm-66.69-237.31 25.31-82.15-67.08-53.62h82.15L720-828.46l26.31 81.15h82.15l-66.69 53.62 25.31 82.15L720-662.15l-66.69 50.61Z" />
            </svg>
          </span>
          <span className="my-auto mx-1 font-semibold text-sm">Lieux</span>
        </div> */}
        </div>
      )}
    </>
  );
};

export default PoiFilter;
